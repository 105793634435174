import dayjs from 'dayjs';
import { sha512 } from 'js-sha512';

export const sha512Encrypt = (str) => sha512(str);
/**
 * Hash 변경
 */
export const hashConvert = (str) => {
  let hash = 0;
  for (let i = 0, len = str.length; i < len; i++) {
    const chr = str.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0;
  }
  return String(hash);
};

/**
 * 날짜 포맷 변경
 */
export const dateConvert = (date, format = 'YYYY-MM-DD') => dayjs(date).format(format);

/**
 * 자리수만큼 0채우기
 */
export const pad = (n, width) => {
  n += '';
  return n.length >= width ? n : new Array(width - n.length + 1).join('0') + n;
};

/**
 * 문자열이 빈 문자열인지 체크하여 결과값을 리턴한다.
 */
export function isEmpty(str) {
  if (
    typeof str === 'undefined' ||
        str === null ||
        str === '' ||
        (str !== null && typeof str === 'object' && Object.keys(str).length) === 0
  ) {
    return true;
  }
  return false;
}

/**
 * 공통정보 조회
 */
export function findObjectByValue(arr, value) {
  if (arr.find((el) => el.value === value) === undefined) {
    return {
      text: value,
    };
  }
  return arr.find((el) => el.value === value);
}

export function numberWithComma(size) {
  return size.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}
