import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';

Vue.use(VueRouter);

const routes = [{
  path: '/',
  name: 'main',
  redirect: { name: 'users' },
},
{
  path: '/dashboard',
  name: 'dashboard',
  meta: { title: '메인' },
  component: () =>
    import(/* webpackChunkName: "dashboard" */ '../views/dashboard/list.vue'),
},
{
  path: '/login',
  name: 'login',
  component: () =>
    import(/* webpackChunkName: "login" */ '../views/login/index.vue'),
},
{
  path: '/users',
  name: 'users',
  meta: { title: '이용자 관리' },
  component: () =>
    import(/* webpackChunkName: "users" */ '../views/users/list.vue'),
},
{
  path: '/agency',
  name: 'agency',
  meta: { title: '기관정보 관리' },
  component: () =>
    import(/* webpackChunkName: "agency" */ '../views/agency/write.vue'),
},
{
  path: '/docType',
  name: 'docType',
  meta: { title: '문서유형 관리' },
  component: () =>
    import(/* webpackChunkName: "docType" */ '../views/docType/list.vue'),
},
{
  path: '/shipment',
  name: 'shipment',
  meta: { title: '발송내역 현황' },
  component: () =>
    import(/* webpackChunkName: "shipment" */ '../views/shipment/list.vue'),
},
{
  path: '/shipment/detail',
  name: 'shipmentDetail',
  meta: { title: '발송문서 상세' },
  component: () =>
    import(/* webpackChunkName: "shipmentDetail" */ '../views/shipment/detail.vue'),
},
{
  path: '/calculate',
  name: 'calculate',
  meta: { title: '정산내역 현황' },
  component: () =>
    import(/* webpackChunkName: "calculate" */ '../views/calculate/list.vue'),
},
{
  path: '/distribution',
  name: 'distribution',
  meta: { title: '유통증명서 발급현황' },
  component: () =>
    import(/* webpackChunkName: "distribution" */ '../views/distribution/list.vue'),
},
{
  path: '/resetPassword',
  name: 'resetPassword',
  meta: { title: '비밀번호 변경' },
  component: () =>
    import(/* webpackChunkName: "resetPassword" */ '../views/password/index.vue'),
},
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  if (to.name === 'login' || to.name === 'resetPassword') {
    await store.dispatch('user/setUser', {});
    await store.dispatch('user/setOrg', {});
  } else {
    const isLogin = store.getters['user/isLogin'];
    if (!isLogin) {
      next({ name: 'login' });
    }
  }

  next();
});

export default router;
