import dayjs from 'dayjs';
import datepickerView from '@/components/datepicker';
import { api } from '@/lib/axios';
import { _constants } from '@/lib/constants';
import { _url } from '@/lib/url';
import { findObjectByValue, dateConvert, numberWithComma } from '@/lib/utils';

export default {
  components: { datepickerView },
  data() {
    return {
      constantsInfo: _constants,
    };
  },
  computed: {
    isLogin() {
      return this.$store.getters['user/isLogin'];
    },
    userInfo() {
      return this.$store.getters['user/getUser'];
    },
    orgInfo() {
      return this.$store.getters['user/getOrg'];
    },
    getCodes() {
      return this.$store.getters['comCode/getCodes'];
    },
  },
  methods: {
    dayjs,
    dateConvert,
    numberWithComma,
    valueToObject(arr, val) {
      return findObjectByValue(arr, val);
    },
    routerNamePush(name, query = {}) {
      this.$router.push({ name, query }).catch(() => {});
    },
    routerPathPush(path, query = {}) {
      this.$router.push({ path, query }).catch(() => {});
    },
    alertPopup(title, content, popupInfo = {}) {
      const titleAddClass = title ? 'swal-alert-title-no' : '';
      return this.$swal.fire({
        title,
        text: content,
        html: content,
        allowOutsideClick: false,
        showCancelButton: false,
        confirmButtonText: popupInfo.confirmText || '확인',
        focusConfirm: false,
        customClass: {
          popup: 'swal-alert-popup',
          image: 'swal-alert-image',
          title: 'swal-alert-title',
          htmlContainer: `swal-alert-html ${titleAddClass}`,
          actions: 'swal-alert-actions',
          confirmButton: 'swal-alert-ok',
        },
      });
    },
    confirmPopup(title, content, popupInfo = {}) {
      const titleAddClass = title ? 'swal-alert-title-no' : '';
      return this.$swal.fire({
        title,
        text: content,
        html: content,
        allowOutsideClick: false,
        showCancelButton: true,
        cancelButtonText: popupInfo.confirmText || '확인',
        confirmButtonText: popupInfo.cancelText || '취소',
        focusConfirm: false,
        customClass: {
          popup: 'swal-alert-popup',
          image: 'swal-alert-image',
          title: 'swal-alert-title',
          htmlContainer: `swal-alert-html ${titleAddClass}`,
          actions: 'swal-alert-actions',
          confirmButton: 'swal-alert-cancel',
          cancelButton: 'swal-alert-ok',
        },
      });
    },
    toastPopup(message) {
      this.$toasted.show(message, {
        position: 'bottom-center',
        duration: 3000,
      });
    },
    dataMasking(data) {
      if (data === null) {
        return null;
      }
      const dataString = data.toString();
      if (dataString.length > 2) {
        let ast = '';
        let i = 0;
        while (i < (dataString.length - 2)) {
          i++;
          ast += '*';
        }
        return dataString.substr(0, 1) + ast + dataString.substr(dataString.length - 1, dataString.length);
      }
      return `${dataString.substr(0, 1)}*`;
    },
    docTypeDownloadHandler(id, type) {
      api.get(`${_url.docType}/${id}/type/${type}`, {
        responseType: 'blob',
      }).then((res) => {
        const url = window.URL.createObjectURL(res);
        const link = document.createElement('a');
        link.href = url;

        const fileName = this.getFileName(type, res.type);
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      }).catch(() => {});
    },
    getFileName(type, responseType) {
      return type === 'desc' ? 'desc.html' : this.getIconFileName(responseType);
    },
    getIconFileName(type) {
      return `icon.${type.replace('image/', '')}`;
    },
    checkDateValidation(fromDate, toDate) {
      if (fromDate > toDate) {
        this.alertPopup('검색일자 오류', '시작일자를 다시 확인해 주세요!');
        return false;
      }

      if (toDate < fromDate) {
        this.alertPopup('검색일자 오류', '종료일자를 다시 확인해 주세요!');
        return false;
      }
      return true;
    },
  },
};
