export const _url = {
  issueToken: `${process.env.VUE_APP_MCC_WAS}/auth/issue-token`,
  refreshToken: `${process.env.VUE_APP_MCC_WAS}/auth/refresh-token`,

  elastic: `${process.env.VUE_APP_ELASTIC}/mcm_sync_idx/_search`,
  notice: `${process.env.VUE_APP_MCC_WAS}/api/notice`,

  usersProfile: `${process.env.VUE_APP_MCM_WAS}/admin/users/profile`,
  adminUsers: `${process.env.VUE_APP_MCM_WAS}/admin/users`,
  docOrg: `${process.env.VUE_APP_MCM_WAS}/api/doc-org`,
  docType: `${process.env.VUE_APP_MCM_WAS}/api/doc-type`,

  edocOut: `${process.env.VUE_APP_MCM_WAS}/api/edoc/out`,
  cert: `${process.env.VUE_APP_MCM_WAS}/api/cert`,
  certDown: `${process.env.VUE_APP_MCM_WAS}/api/cert`, // 유통증명서 다운로드
  push: `${process.env.VUE_APP_MCM_WAS}/api/edoc/push`,

  commission: `${process.env.VUE_APP_MCC_WAS}/api/commission`,
  adjust: `${process.env.VUE_APP_MCC_WAS}/api/adjust`,

  resetpasswd: `${process.env.VUE_APP_MCM_WAS}/admin/users/resetpasswd`,
  chpasswd: `${process.env.VUE_APP_MCM_WAS}/chpasswd`,

};
