import Vue from 'vue';

import VueSweetalert2 from 'vue-sweetalert2';
import Toasted from 'vue-toasted';
import VueSlideoutPanel from 'vue2-slideout-panel';
import App from './App.vue';

import router from './router';
import store from './store';
import CommonMixins from '@/mixins/common';
import 'sweetalert2/dist/sweetalert2.css';
import 'c-swipe/dist/swipe.css';

import './assets/css/base.css';
import './assets/css/common.css';
import './assets/css/custom.css';

Vue.mixin(CommonMixins);
Vue.use(VueSlideoutPanel);
Vue.use(VueSweetalert2);
Vue.use(Toasted);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
