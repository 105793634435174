import axios from 'axios';
import Vue from 'vue';

import bus from '@/lib/bus';
import { _url } from '@/lib/url';
import router from '@/router';
import store from '@/store';

const alertPopup = (title, content) => {
  const titleAddClass = title ? 'swal-alert-title-no' : '';
  Vue.swal.fire({
    title,
    text: content,
    allowOutsideClick: false,
    showCancelButton: false,
    confirmButtonText: '확인',
    focusConfirm: false,
    customClass: {
      popup: 'swal-alert-popup',
      image: 'swal-alert-image',
      title: 'swal-alert-title',
      htmlContainer: `swal-alert-html ${titleAddClass}`,
      actions: 'swal-alert-actions',
      confirmButton: 'swal-alert-ok',
    },
  });
};

const checkExpired = () => {
  const sessionInfo = store.getters['session/getSession'];
  const time = sessionInfo.expiredOn;
  // const time = '2023-03-28T16:40:58';
  const expiredTime = new Date(time);
  const now = new Date();
  let result = false;

  const gap = expiredTime.getTime() - now.getTime();
  const minGap = gap / 1000 / 60;

  if (minGap <= 1) {
    result = true;
  }
  return result;
};

const handleTokenRefresh = () => {
  const sessionInfo = store.getters['session/getSession'];

  return new Promise((resolve, reject) => {
    axios
      .post(_url.refreshToken, {
        refreshToken: sessionInfo.refreshToken,
      })
      .then(({ data }) => {
        store.dispatch('session/setSession', data);
        resolve(data.accessToken);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const attachTokenToRequest = (request, token) => {
  request.headers.Authorization = `Bearer ${token}`;
};

function addInterceptor(instance, auth) {
  instance.interceptors.request.use(
    async (config) => {
      // config.timeout = 1;
      store.commit('loading', true);
      if (auth) {
        if ((await checkExpired()) === true) {
          await handleTokenRefresh();
        }
        const sessionInfo = store.getters['session/getSession'];
        attachTokenToRequest(config, sessionInfo.accessToken);
      }
      return config;
    },
    (error) => Promise.reject(error),
  );

  instance.interceptors.response.use(
    (response) => {
      store.commit('loading', false);
      return response.data;
    },
    (error) => {
      store.commit('loading', false);
      if (error.response.status === 401) {
        alertPopup(error.code, error.message).then(() => {
          router.push('login');
          bus.$emit(error.code, error);
          return Promise.reject(error);
        });
      }
      // ERR_NETWORK : 네트워크 에러
      // ECONNABORTED : 타임아웃
      // ERR_BAD_REQUEST : 요청정보 오류
      if (['ERR_NETWORK', 'ECONNABORTED'].includes(error.code)) {
        alertPopup(error.code, error.message);
        // 네트워크 오류일 경우 어떻게 처리 할지 논의 필요
        bus.$emit(error.code, error);
        return Promise.reject(error);
      }
      if (['ERR_BAD_REQUEST', 'ERR_BAD_RESPONSE'].includes(error.code)) {
        if (error.response.data.errCode) {
          alertPopup(error.response.data.errCode, error.response.data.errMsg);
        } else {
          alertPopup(error.code, error.message);
        }

        // 네트워크 오류일 경우 어떻게 처리 할지 논의 필요
        bus.$emit(error.code, error);
        return Promise.reject(error);
      }
      return Promise.reject(error);
    },
  );
  return instance;
}

const api = addInterceptor(axios.create(), true);
const http = addInterceptor(axios.create(), false);

export { api, http };
