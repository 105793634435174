export const _constants = {
  userStatus: [{
    label: 'ALL',
    text: '전체',
    value: '',
  },
  {
    label: 'SUSPENDED',
    text: '중지',
    value: 0,
  },
  {
    label: 'USING',
    text: '이용',
    value: 1,
  },
  {
    label: 'STANDBY',
    text: '대기',
    value: 2,
  },
  {
    label: 'PASSWORD_RESET',
    text: '비밀번호 초기화 요청',
    value: 3,
  },
  {
    label: 'ADD_WAITING',
    text: '등록 요청',
    value: 4,
  },
  ],
  roleType: [{
    label: 'USER',
    text: '이용자',
    value: 0,
  },
  {
    label: 'ADMIN',
    text: '관리자',
    value: 1,
  },
  {
    label: 'PEER',
    text: '중계자',
    value: 2,
  },
  ],
  userType: [{
    label: 'All',
    text: '전체',
    value: '',
  },
  {
    label: 'INDIVISUAL',
    text: '개인',
    value: 0,
  },
  {
    label: 'CORP',
    text: '법인',
    value: 1,
  },
  {
    label: 'STATE_AGENCY',
    text: '국가기관',
    value: 2,
  },
  {
    label: 'PUBLIC_INSTITUTIONS',
    text: '공공기관',
    value: 3,
  },
  {
    label: 'LOCAL_GOVERNMENT',
    text: '지자체',
    value: 4,
  },
  {
    label: 'ETC',
    text: '기타',
    value: 5,
  },
  ],
  docTypeGroupCodes: [{
    text: '납부고지서',
    value: '01',
  },
  {
    text: '명세서',
    value: '02',
  },
  {
    text: '안내문',
    value: '03',
  },
  {
    text: '고지서',
    value: '04',
  },
  {
    text: '기타',
    value: '99',
  },
  ],
  sendStatus: [
    { label: 'SEND_DONE', text: '송신 완료', value: 0 },
    { label: 'RECEIVE_DONE', text: '수신 완료', value: 1 },
    { label: 'READ_DONE', text: '열람 완료', value: 2 },
    { label: 'SEND_FAIL', text: '송신 실패', value: 3 },
    { label: 'RECEIVE_FAIL', text: '수신 실패', value: 4 },
    { label: 'READ_FAIL', text: '열람 실패', value: 5 },
    { label: 'TEMP_SAVE', text: '임시 저장', value: 6 },
    { label: 'DELETED', text: '삭제됨', value: 7 },
    { label: 'CHECK', text: '확인 완료', value: 8 },
  ],
  receiveStatus: [
    { label: 'NOT_DONE', text: '미수신', value: 0 },
    { label: 'DONE', text: '수신', value: 1 },
  ],
  readStatus: [
    { label: 'NOT_DONE', text: '미열람', value: 0 },
    { label: 'DONE', text: '열람', value: 1 },
  ],
  calculateStatus: [
    { label: 'NO', text: '미정산', value: 'NO' },
    { label: 'ING', text: '정산진행', value: 'ING' },
    { label: 'ADJUST', text: '조정완료', value: 'ADJUST' },
    { label: 'FINISH', text: '정산확정', value: 'FINISH' },
  ],
};
