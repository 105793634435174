<template>
  <!-- aside -->
  <div class="aside">
    <template v-if="isLogin">
      <a class="btn btn-logo" @click="routerNamePush('dashboard')">
        <span class="blind">우리은행</span>
      </a>
      <div class="aside-wrap">
        <ul class="snb">
          <li :class="['item', $route.name === 'users' ? 'active' : '']">
            <a class="btn" @click="routerNamePush('users')">
              <span class="txt">이용자 관리</span>
            </a>
          </li>
          <li :class="['item', $route.name === 'agency' ? 'active' : '']">
            <a class="btn" @click="routerNamePush('agency')">
              <span class="txt">기관정보 관리</span>
            </a>
          </li>
          <li :class="['item', $route.name === 'docType' ? 'active' : '']">
            <a class="btn" @click="routerNamePush('docType')">
              <span class="txt">문서유형 관리</span>
            </a>
          </li>
          <li :class="['item', $route.name === 'shipment' ? 'active' : '']">
            <a class="btn" @click="routerNamePush('shipment')">
              <span class="txt">발송내역 현황</span>
            </a>
          </li>
          <li :class="['item', $route.name === 'calculate' ? 'active' : '']">
            <a class="btn" @click="routerNamePush('calculate')">
              <span class="txt">정산내역 현황</span>
            </a>
          </li>
          <li :class="['item', $route.name === 'distribution' ? 'active' : '']">
            <a class="btn" @click="routerNamePush('distribution')">
              <span class="txt">유통증명서 발급현황</span>
            </a>
          </li>
        </ul>
      </div>
    </template>
  </div>
  <!-- //aside -->
</template>

<script>
export default {
  name: 'asideView',
  data() {
    return {};
  },
  created() {
  },
  methods: {},
};
</script>

<style scoped></style>
