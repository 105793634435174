<template>
  <div id="app" class="wrapper">
    <!-- header -->
    <header-view :key="isLogin" @popupPasswordOpen="popupPasswordOpen" />
    <!-- // header -->

    <!-- aside -->
    <aside-view :key="$route.fullPath" />
    <!-- //aside -->

    <!-- content -->
    <div :class="['content', !isLogin ? 'login' : '']">
      <router-view :key="$route.fullPath" />
    </div>
    <!-- // content -->

    <popup-pass-word-view v-if="isPopupPassWord" v-model="isPopupPassWord" />

    <transition name="fade">
      <div v-show="isLoadingBar" class="loading_center">
        <div class="loading_layer">
          <div class="ball01"></div>
          <div class="ball02"></div>
          <div class="ball03"></div>
          <div class="ball04"></div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import asideView from '@/components/layout/aside';
import headerView from '@/components/layout/header';
import popupPassWordView from '@/components/popup/password';

export default {
  name: 'app',
  components: { headerView, asideView, popupPassWordView },
  data() {
    return {
      isPopupPassWord: false,
    };
  },
  computed: {
    ...mapState(['isLoadingBar', 'refLoadingBarCount']),
  },
  created() {},
  mounted() {},
  methods: {
    popupPasswordOpen() {
      this.isPopupPassWord = true;
    },
  },
};
</script>
