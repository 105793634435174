export default {
  namespaced: true,

  state: {
    session: null,
  },

  getters: {
    getSession: (state) => state.session,
  },

  mutations: {
    SET_SESSION(state, session) {
      state.session = session;
    },
  },
  actions: {
    setSession({ commit }, session) {
      commit('SET_SESSION', session);
    },
  },
};
