import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import comCode from './modules/code';
import session from './modules/session';
import user from './modules/user';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    refLoadingBarCount: 0,
    isLoadingBar: false,
  },
  getters: {},
  mutations: {
    loading(state, isLoadingBar) {
      if (isLoadingBar) {
        state.refLoadingBarCount++;
        state.isLoadingBar = true;
      } else if (state.refLoadingBarCount > 0) {
        state.refLoadingBarCount--;
        state.isLoadingBar = state.refLoadingBarCount > 0;
      }
    },
  },
  actions: {},
  modules: {
    namespace: true,
    user,
    session,
    comCode,
  },
  plugins: [
    createPersistedState({
      storage: window.sessionStorage,
      paths: ['comCode', 'user', 'session'],
    }),
  ],
});
