<template>
  <date-picker
    v-model="date"
    valueType="format"
    :format="type === 'date' ? 'YYYY-MM-DD' : 'YYYY-MM'"
    input-class="inp inp-date w150"
    :type="type"
    @change="change"
  />
</template>

<script>
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/ko.js';

export default {
  name: 'datepickerView',
  components: { DatePicker },
  props: {
    value: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'date',
    },
  },
  data() {
    return {
    };
  },
  computed: {
    date: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit('input', v);
      },
    },
  },
  methods: {
    change(date) {
      this.$emit('change', date);
    },
  },
};
</script>
