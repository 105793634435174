<template>
  <div id="modal" class="modal-wrap active">
    <div class="popup-wrap">
      <div class="popup-header">
        <p class="tit16">비밀번호 변경</p>
      </div>
      <div class="popup-body">
        <p class="txt14">* 회원님의 개인정보를 안전하게 보호하고, 개인정보 도용으로 인한 피해를 예방하기 위해 3개월 마다 비밀번호 변경하시길 권장합니다.</p>
        <!-- tbl-wrap -->
        <div class="tbl-wrap">
          <table class="tbl">
            <caption>
              전자고지 서비스 발송기관 웹 포털 이용자 관리
            </caption>
            <colgroup>
              <col width="180px" />
              <col width="auto" />
            </colgroup>
            <tbody>
              <tr>
                <th scope="row"><label for="inp1" class="txt14 req">현재 비밀번호</label></th>
                <td>
                  <input
                    id="inp1"
                    type="password"
                    placeholder="현재 비밀번호를 입력하세요"
                    class="inp w100p"
                  />
                </td>
              </tr>
              <tr>
                <th scope="row"><label for="inp2" class="txt14 req">새 비밀번호</label></th>
                <td>
                  <input
                    id="inp2"
                    type="password"
                    placeholder="영문/대소문자/숫자/특수문자 중 2가지 이상 조합, 8자리 이상"
                    class="inp w100p"
                  />
                </td>
              </tr>
              <tr>
                <th scope="row"><label for="inp3" class="txt14 req">비밀번호 확인</label></th>
                <td>
                  <input
                    id="inp3"
                    type="password"
                    placeholder="비밀번호를 확인해주세요"
                    class="inp w100p"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- // tbl-wrap -->
        <div class="btn-wrap">
          <button type="button" class="btn btn-primary medium"><span class="txt">비밀번호 변경</span></button>
        </div>
      </div>
      <div class="popup-close">
        <button type="button" class="btn btn-close2" @click="show = false">
          <span class="blind">닫기</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'popupPassWordView',
  data() {
    return {};
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set() {
        this.$emit('input', false);
      },
    },
  },
  mounted() {},
  beforeDestroy() {},
  methods: {},
};
</script>
