import { isEmpty } from '@/lib/utils';

export default {
  namespaced: true,

  state: {
    user: null,
    org: null,
  },

  getters: {
    getUser: (state) => state.user,
    isLogin: (state) => !isEmpty(state.user),
    getOrg: (state) => state.org,
  },

  mutations: {
    SET_USER(state, user) {
      state.user = user;
    },
    SET_ORG(state, org) {
      state.org = org;
    },
  },
  actions: {
    setUser({ commit }, user) {
      commit('SET_USER', user);
    },
    setOrg({ commit }, org) {
      commit('SET_ORG', org);
    },
  },
};
