<template>
  <!-- header -->
  <div v-if="!isLogin" class="login-top">
    <a class="btn"><img src="@/assets/img/ico_logo2.png" alt="우리은행" /></a>
    <span class="txt20">전자고지</span>
  </div>
  <div v-else class="header">
    <div class="header-wrap">
      <span class="txt15">전자고지 서비스 발송기관 웹 포털</span>
      <span v-if="env === 'development'" class="txt15" style="color:red; margin-left: 10px"> [개발]</span>
      <span v-if="env === 'staging'" class="txt15" style="color:red; margin-left: 10px"> [검증]</span>
      <div class="user-wrap">
        <span class="txt15">
          <em class="name">{{ userInfo.userName }}</em
          >님 안녕하세요.
        </span>
        <!-- <a class="btn btn-pw" @click="passwordResetClickHandler()">
          <span class="txt">비밀번호 변경</span>
        </a> -->
        <a class="btn btn-logout" @click="routerNamePush('login')">
          <span class="txt">Logout</span>
        </a>
      </div>
    </div>
  </div>
  <!-- // header -->
</template>

<script>
import { api } from '@/lib/axios';
import { _url } from '@/lib/url';

export default {
  name: 'headerView',
  props: {
  },
  data() {
    return {
      env: process.env.NODE_ENV,
    };
  },
  created() {},
  methods: {
    passwordResetClickHandler() {
      this.confirmPopup('', '비밀번호를 초기화 하시겠습니까?').then((result) => {
        if (!result.isConfirmed) {
          api.patch(`${_url.resetpasswd}/${this.userInfo.id}`).then(() => {
            alert(1);
          }).catch(() => {});
        }
      });
    },
  },
};
</script>

<style scoped></style>
