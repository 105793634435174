export default {
  namespaced: true,

  state: {
    codes: {},
  },

  getters: {
    getCodes: (state) => state.codes,
  },

  mutations: {
    SET_CODES(state, codes) {
      state.codes = codes;
    },
  },

  actions: {
    setCodes({ commit }, code) {
      commit('SET_CODES', code);
    },
  },
};
